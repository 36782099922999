<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="status" @change="loadLandContracts(true)">
                <el-radio-button :label="98">完结归档</el-radio-button>
                <el-radio-button :label="99">作废归档</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadLandContracts(true)" />
                </div>
            </div>
            <el-table :data="landContracts.data" class="mt-2">
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/land-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="partnerName" label="土地来源方" sortable />
                <el-table-column prop="contractArea" label="合同面积(亩)" sortable width="140" />
                <el-table-column prop="contractAmount" label="合同金额(元)" sortable width="140" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="lands" label="土地划分" sortable width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showLandsDialog(scope.row)">
                            {{scope.row.lands}} 块
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="landContracts.totalRecords"
                :current-page="landContractsPagination.currentPage" :page-size="landContractsPagination.pageSize"
                class="mt-3" @current-change="landContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="landsDialogVisible" title="最小单元土地划分" :close-on-click-modal="false" width="960px"
            @closed="loadLandContracts">
            <lands v-if="landsDialogVisible" :land-contract-id="landContract.id" />
        </el-dialog>
    </div>
</template>

<script>
    import Lands from './components/Lands.vue';

    export default {
        data() {
            return {
                status: 98,
                key: null,
                landContracts: {},
                landContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                landContract: {},
                landsDialogVisible: false,
            };
        },
        components: { Lands },
        methods: {
            async loadLandContracts(resetPage = false) {
                if (resetPage) {
                    this.landContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/LandContract/GetLandContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: this.status,
                        key: this.key,
                        pageIndex: this.landContractsPagination.currentPage - 1,
                        pageSize: this.landContractsPagination.pageSize,
                    }
                });
                this.landContracts = response.data;
            },
            landContractsPaginationCurrentChange(page) {
                this.landContractsPagination.currentPage = page;
                this.loadLandContracts();
            },
            showLandsDialog(landContract) {
                this.landContract = JSON.parse(JSON.stringify(landContract));
                this.landsDialogVisible = true;
            },
        },
        created() {
            this.loadLandContracts();
        },
    };
</script>